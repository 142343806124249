// api.js

import axios from 'axios';
const baseURL = 'https://dienbien.tech3art.com';

const api = axios.create({
  baseURL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

const endpoints = {
  getAllArtifacts: () => `/api/artifact`,
  getArtifact: (id) => `/api/artifact/${id}`,

};

const apiCalls = {
 
  getAllArtifacts: async () => {
    try {
      const response = await api.get(endpoints.getAllArtifacts());
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getArtifact:async(id)=>{
    try {
      const response = await api.get(endpoints.getArtifact(id));
      return response.data;
    } catch (error) {
      throw error;
    }
  },

};

export default apiCalls;
